import { lib_names } from "../libs/lib.names";

//	T Y P E

import { type___locale___language } from "../types/types.locale";

//	E X P O R T

export class service_LocalizationService {

//#region 																							S E T   L A N G U A G E

	public language_get() : type___locale___language
	{
		const ___language_selected: type___locale___language = localStorage.getItem(lib_names.localStorage.user___language) as type___locale___language | null ?? 'it_it';
		return ___language_selected as type___locale___language;
	}

//#endregion

//#region 																							S E T   L A N G U A G E

	public language_set(___language: type___locale___language) : void
	{
		localStorage.setItem(lib_names.localStorage.user___language, ___language);
		return;
	}

//#endregion

//#region 																							T R A N S L A T E

	public translate(___input: any, ___params: string[] = []) : string
	{

		const ___language_selected: type___locale___language = this.language_get();
		let ___string___input: string = ___input[___language_selected] as string;

		for (let ___i_p = 0; ___i_p < ___params.length; ___i_p++)
		{
			const ___param___placeholder: string = '{{' + (___i_p + 1).toString() + '}}'; 
			const ___param___value: string = ___params[___i_p];
			___string___input = ___string___input.replace(___param___placeholder, ___param___value);
		}

		return ___string___input as string;

	}

//#endregion

}