import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComButtonShareComponent } from './com-button-share.locale';

//	S T Y L E

import './com-button-share.scss'

//	S T A T E S   -   P R O P S

import { props___ComButtonShareComponent, state___ComButtonShareComponent, state___ComButtonShareComponent___default } from './com-button-share.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';
import { service_Sharing } from '../../services/service-sharing';

//	C L A S S

export default class ComButtonShareComponent extends React.Component<props___ComButtonShareComponent, state___ComButtonShareComponent> {

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _SHARE: service_Sharing = new service_Sharing();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComButtonShareComponent,
	) {
		super(props);
		this.state = state___ComButtonShareComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___share___external = async () => {
		const ___url_partial___untrimmed: string = this.state.element___url;
		const ___url_partial: string = (___url_partial___untrimmed.startsWith('/')) ? ___url_partial___untrimmed.substring(1) : ___url_partial___untrimmed;
		const ___is_shared: 'shared' | 'copyed' | 'null' = await this._SHARE.share(this.state.element___title, ___url_partial);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount(): void {
		switch (this.props.element_type) {
			case 'event':
				this.setState({
					element___title: this.props.element['event_name'],
					element___url: lib_names.share_urls['events'] + '/' + this.props.element['event_id'],
				});
			break;
			case 'poi':
				this.setState({
					element___title: this.props.element['poi_name'],
					element___url: lib_names.share_urls['poi'] + '/' + this.props.element['poi_id'],
				});
			break;
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode {
		return <>	
			<div className="com-button-share---container">
				<div onClick={ this.handle___share___external }>
					<i className="fas fa-share-alt"></i>
					<span>{ this._LOCALE.translate(locale___ComButtonShareComponent.share___external) }</span>
				</div>
			</div>
		</>;
	}

//#endregion

}