import React from 'react';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';

//	C S S   D E F A U L T S

import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

//	T H E M E   F O N T S

import './theme/theme.fonts/font-awesome/css/all.min.css';
import './theme/theme.fonts/quicksand/quicksand.css';

//	T H E M E

import './theme/theme.reset.scss';
import './theme/theme.variables.scss';
import './theme/theme.global.scss';
import './theme/theme.mapbox.scss';

//	T Y P E S

import { type___cookies___accepted } from './types/types.cookies';
import { type___geo___lngLat } from './types/types.types';
import { type___locale___language } from './types/types.locale';

//	S T A T E   -   P R O P S

import { state___App, state___App___default } from './App.state';

//	L I B S

import { lib_names } from './libs/lib.names';

//	S E R V I C E S

import { service_GeolocationService } from './services/service-geolocation';
import { service_DeepLinksService } from './services/service-deeplinks';

//	P A G E S

import Sb404Page from './pages/sb-404/sb-404';

import SbDiscoverMapPage from './pages/sb-discover-map/sb-discover-map';

import SbEventsViewPage from './pages/sb-events-view/sb-events-view';

import SbEventsCategoryViewPage from './pages/sb-events-category-view/sb-events-category-view';

import SbInfoCopyrightPage from './pages/sb-info/sb-info-copyright/sb-info-copyright';
import SbInfoDigitalMenuAllergensPage from './pages/sb-info/sb-info-digitalmenu-allergens/sb-info-digitalmenu-allergens';
import SbInfoEulaPage from './pages/sb-info/sb-info-eula/sb-info-eula';
import SbInfoLicencesPage from './pages/sb-info/sb-info-licences/sb-info-licences';
import SbInfoPresentationPage from './pages/sb-info/sb-info-presentation/sb-info-presentation';
import SbInfoStatsPage from './pages/sb-info/sb-info-stats/sb-info-stats';
import SbInfoSupportPage from './pages/sb-info/sb-info-support/sb-info-support';
import SbInfoThanksPage from './pages/sb-info/sb-info-thanks/sb-info-thanks';

import SbLandingGeolocationPage from './pages/sb-landing/sb-landing-geolocation/sb-landing-geolocation';
import SbLandingLoadingPage from './pages/sb-landing/sb-landing-loading/sb-landing-loading';

import SbPoiViewPage from './pages/sb-poi/sb-poi-view/sb-poi-view';
import SbPoiViewDigitalMenuPage from './pages/sb-poi/sb-poi-view-digitalmenu/sb-poi-view-digitalmenu';

import SbPoiCategoryViewPage from './pages/sb-poi-category-view/sb-poi-category-view';

//	M O D A L S

import MdEntryCookiesModal from './modals/md-entry-cookies/md-entry-cookies';
import MdEntryDownloadAppModal from './modals/md-entry-download-app/md-entry-download-app';
import MdEntryLanguageModal from './modals/md-entry-language/md-entry-language';

//	S E T U P

setupIonicReact({ mode: 'ios' });

//	C L A S S

export default class App extends React.Component<{}, state___App> {

//#region 																							D E C L A R A T I O N S

	private _ABORT_CONTROLLER: AbortController | null = null;

	private readonly _GEOLOCATION: service_GeolocationService = new service_GeolocationService();
	private readonly _DEEPLINKS: service_DeepLinksService = new service_DeepLinksService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: {},
	) {
		super(props);
		this.state = state___App___default;
		if (!this._ABORT_CONTROLLER) { this._ABORT_CONTROLLER = new AbortController(); }
	}

//#endregion

//#region 																							A B O R T   C O N T R O L L E R

	private AbortController___abort() : void {
		if (this._ABORT_CONTROLLER) { this._ABORT_CONTROLLER.abort(); }
	}

//#endregion

//#region 																							E N T R Y

	private entry___initialize = () => {
		const ___language___selected: type___locale___language | null = localStorage.getItem(lib_names.localStorage.user___language) as type___locale___language | null;
		this.setState({
			entry___language_selected: ___language___selected
		}, () => {

			

			if (___language___selected && ___language___selected !== null) {


				this.setState({
					app___isLoading: false,
				}, () => {
					
					const ___cookies___accepted: type___cookies___accepted = localStorage.getItem(lib_names.localStorage.user___cookies_accepted) as type___cookies___accepted;
					this.setState({
						entry___cookies_accepted: ___cookies___accepted
					}, () => {
						if (!(___cookies___accepted && ___cookies___accepted !== null)) {
							this.setState({ ionModal___MdEntryCookiesModal___isOpen: true });
						}
					});
				});
			} else {
				this.setState({
					ionModal___MdEntryLanguageModal___isOpen: true
				});
			}
		});
	}

	private entry___setup___language = (___l: type___locale___language) => {
		this.setState({
			ionModal___MdEntryLanguageModal___isOpen: false,
			entry___language_selected: ___l,
			app___isLoading: false,
		}, () => {
			this.entry___initialize();
		});
	}

	private entry___setup___cookies = (___ca: type___cookies___accepted) => {
		this.setState({
			ionModal___MdEntryCookiesModal___isOpen: false,
			entry___cookies_accepted: ___ca,
			app___isLoading: false,
		}, () => {
			this.entry___initialize();
		});
	}

	private entry___geolocation = () => {
		(async () => {
			const ___geolocation___result: type___geo___lngLat = await this._GEOLOCATION.geolocation___locate();
			if (___geolocation___result['error'] && ___geolocation___result['error'] !== null) {
				this.setState({
					entry___geolocation_available: false
				});
			} else {
				this.setState({
					entry___geolocation_available: true
				});
			}
		})();
	}

	private entry___app___download = () => {
		setTimeout(() => {
			this.setState({
				ionModal___MdEntryDownloadAppModal___isOpen: true
			});
		}, 17500);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void> {
		this._DEEPLINKS.openDeepLink();
		this.AbortController___abort();
		this.entry___initialize();
		this.entry___geolocation();
		this.entry___app___download();
	}

	componentWillUnmount() : void {
		this.AbortController___abort();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonApp>
				<IonReactRouter>
	
					<MdEntryCookiesModal isOpen={ this.state.ionModal___MdEntryCookiesModal___isOpen } event___onDidDismiss={ this.entry___setup___cookies }/>				
					<MdEntryDownloadAppModal isOpen={ this.state.ionModal___MdEntryDownloadAppModal___isOpen } event___onDidDismiss={() => { this.setState({ ionModal___MdEntryDownloadAppModal___isOpen: false }); }}/>
					<MdEntryLanguageModal isOpen={ this.state.ionModal___MdEntryLanguageModal___isOpen } isDismissible={ false }  event___onDidDismiss={ this.entry___setup___language }/>
					
					{(this.state.app___isLoading === true) ? <>
						<SbLandingLoadingPage/>
					</> : <>
						{(this.state.entry___language_selected !== null && this.state.entry___cookies_accepted !== null) ? <>
							<IonRouterOutlet>

								{/*      4 0 4      */}

								<Route path="/404" component={(___p: any) => { return <Sb404Page {...___p} /> }} exact />

								{/*      d i s c o v e r      */}
							
								<Route path="/discover/map" component={(___p: any) => { switch (this.state.entry___geolocation_available) {
									case true: return <><SbDiscoverMapPage {...___p} /> </>; break;
									case false: return <><SbLandingGeolocationPage event___onGeolocationAvailable={() => { this.entry___geolocation(); }}/></>; break;
									default: case null: return <><SbLandingLoadingPage/></>; break;
								}}} exact />

								{}

								{/*      e v e n t s      */}

								<Route path="/view/event/:id" component={(___p: any) => { return <SbEventsViewPage {...___p} /> }} exact />
								<Route path="/view/events/:id" component={(___p: any) => { return <SbEventsViewPage {...___p} /> }} exact />

								{/*      e v e n t s   c a t e g o r y      */}
								
								<Route path="/view/events/category/:id" component={(___p: any) => { return <SbEventsCategoryViewPage {...___p} /> }} exact />

								{/*      i n f o      */}

								<Route path="/info/copyright" component={(___p: any) => { return <SbInfoCopyrightPage {...___p} /> }} exact />
								<Route path="/info/digitalmenu/allergens" component={(___p: any) => { return <SbInfoDigitalMenuAllergensPage {...___p} /> }} exact />
								<Route path="/info/eula" component={(___p: any) => { return <SbInfoEulaPage {...___p} /> }} exact />
								<Route path="/info/licences" component={(___p: any) => { return <SbInfoLicencesPage {...___p} /> }} exact />
								<Route path="/info/presentation" component={(___p: any) => { return <SbInfoPresentationPage {...___p} /> }} exact />
								<Route path="/info/stats" component={(___p: any) => { return <SbInfoStatsPage {...___p} /> }} exact />
								<Route path="/info/support" component={(___p: any) => { return <SbInfoSupportPage {...___p} /> }} exact />
								<Route path="/info/thanks" component={(___p: any) => { return <SbInfoThanksPage {...___p} /> }} exact />

								{/*      p o i      */}

								<Route path="/view/poi/:id" component={(___p: any) => { return <SbPoiViewPage {...___p} /> }} exact />
								<Route path="/view/poi/:id/digitalmenu" component={(___p: any) => { return <SbPoiViewDigitalMenuPage {...___p} /> }} exact />

								{/*      p o i   c a t e g o r y      */}

								<Route path="/view/poi/category/:id" component={(___p: any) => { return <SbPoiCategoryViewPage {...___p} /> }} exact />

								{/*      r e d i r e c t      */}
								
								<Redirect to="/discover/map" />
							</IonRouterOutlet>
						</> : <>
							<SbLandingLoadingPage/>
						</>}
					</>}

				</IonReactRouter>
			</IonApp>
		</>;
	}

//#endregion

}