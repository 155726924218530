//	E X P O R T

export class service_DeepLinksService {

	public openDeepLink() {
		const ___url___complete: string = window.location.href;
		const ___url___remaining: string = ___url___complete.replace(/^(?:https?:\/\/)?(?:www\.)?[^/]+/, "");
		const ___deepLink___string: string = 'nearby://' + ___url___remaining;
		window.location.href = ___deepLink___string;
	}

}