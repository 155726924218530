import React from 'react';
import { IonButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonModal, IonToolbar } from '@ionic/react';

//	L O C A L E

import { locale___App } from '../../App.locale';
import { locale___MdEntryLanguageModal } from './md-entry-language.locale';

//	T Y P E S

import { type___locale___language } from '../../types/types.locale';
import { type___modal___config } from '../../types/types.modals';

//	S T Y L E

import './md-entry-language.scss';

//	S T A T E   -   P R O P S

import { props___MdEntryLanguageModal, state___MdEntryLanguageModal, state___MdEntryLanguageModal___default } from './md-entry-language.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class MdEntryLanguageModal extends React.Component<props___MdEntryLanguageModal, state___MdEntryLanguageModal>
{

//#region 																							C O N F I G

	private readonly MdEntryLanguageModal___modal_config: type___modal___config = (this.props.isDismissible && this.props.isDismissible === true) ? {
		breakpoints: [ 1 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	} : {
		backdropDismiss: false,
		handle: false,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidPresent: () => { this.event___onDidPresent(); },
		onDidDismiss: () => { this.event___onDidDismiss(); },
	};

//#endregion

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private gui_timeout___text: NodeJS.Timeout | null = null;
	private gui_timeout___text___elements: string[] = [
		locale___MdEntryLanguageModal.hint___select_a_language_to_begin['it_it'],
		locale___MdEntryLanguageModal.hint___select_a_language_to_begin['en_us'],
		locale___MdEntryLanguageModal.hint___select_a_language_to_begin['es_es'],
		locale___MdEntryLanguageModal.hint___select_a_language_to_begin['fr_fr'],
		locale___MdEntryLanguageModal.hint___select_a_language_to_begin['de_de'],
	];

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdEntryLanguageModal,
	) {
		super(props);
		this.state = state___MdEntryLanguageModal___default;
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___language_select = (___language: type___locale___language) => {
		this._LOCALE.language_set(___language);
		this.gui_timeout___clear();
		this.props.event___onDidDismiss(___language);

		//	users_settings___language API

	}

//#endregion

//#region 																							G U I   T I M E O U T

	gui_timeout___clear = () => {
		if (this.gui_timeout___text) {
			clearTimeout(this.gui_timeout___text);
		}
	}

//#endregion

//#region 																							H A N D L E R S

	private event___onDidPresent() : void {
		if (this.gui_timeout___text === null) {
			this.gui_timeout___text = setInterval(() => {
				const ___prev___gui_timeout___text_elements___index: number = this.state.gui_timeout___text_elements___index;
				const ___prev___gui_timeout___text_elements___index___next: number = (___prev___gui_timeout___text_elements___index + 1) % this.gui_timeout___text___elements.length;
				this.setState({
					gui_timeout___text_elements___index: ___prev___gui_timeout___text_elements___index___next,
				});
			}, 3500);
		}
	}

	private event___onDidDismiss() : void {
		this.gui_timeout___clear();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntryLanguageModal___modal_config }>

				{(this.props.isDismissible && this.props.isDismissible === true) ? <>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton onClick={ this.props.event___onDidDismiss }>
									<b>{ this._LOCALE.translate(locale___App.modal___topBar___button___cancel) }</b>
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
				</> : <></>}

				<IonContent forceOverscroll={ false }>
					<div className="md-entry-language---container">

						<div className="md-entry-language---header">
							<img src="/assets/logo.horizontal.svg"/>
							<br />
							<h4>{ this.gui_timeout___text___elements[this.state.gui_timeout___text_elements___index] }</h4>
						</div>

						<br />
						<br />
						<br />

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.handle___language_select('it_it'); }}>
									<img src={ '/assets/localization/locale.' + 'it_it' + '.svg' }/>
									<IonLabel style={{textAlign:'center'}}><b>Italiano</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>
					
						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.handle___language_select('en_us'); }}>
									<img src={ '/assets/localization/locale.' + 'en_us' + '.svg' }/>
									<IonLabel style={{textAlign:'center'}}><b>English</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>


						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.handle___language_select('de_de'); }}>
									<img src={ '/assets/localization/locale.' + 'de_de' + '.svg' }/>
									<IonLabel style={{textAlign:'center'}}><b>Deutsch</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.handle___language_select('fr_fr'); }}>
									<img src={ '/assets/localization/locale.' + 'fr_fr' + '.svg' }/>
									<IonLabel style={{textAlign:'center'}}><b>Francaise</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

						<IonCard>
							<IonList>
								<IonItem className="is-ion-item-button" button={ true } onClick={() => { this.handle___language_select('es_es'); }}>
									<img src={ '/assets/localization/locale.' + 'es_es' + '.svg' }/>
									<IonLabel style={{textAlign:'center'}}><b>Espanol</b></IonLabel>
								</IonItem>
							</IonList>
						</IonCard>

					</div>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}